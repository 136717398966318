import { Box } from '@chakra-ui/react';

type ProgressBarProps = {
  progress: number;
  hide?: boolean;
};

/**
 *
 * @param param.progress: number - progress value in percentage
 * @returns JSX.Element
 */
export const ProgressBar = ({ progress, hide = true }: ProgressBarProps) => {
  return (
    <Box w="full" h="8px" bg="#E0E0E0" opacity={hide ? 0 : 1} transition="opacity 300ms ease-in-out">
      <Box
        h="100%"
        width={`${progress}%`}
        bg="linear-gradient(90deg, #8DE690 2.08%, #A5F0A3 18.23%, #A6ECD7 33.33%, #97DADA 51.56%, #B3DAE7 67.71%, #B6C9EC 83.33%, #879ACB 100%)"
      ></Box>
    </Box>
  );
};

import { RefObject } from 'react';

export const CART_QUERY_KEY = 'QUERY/CART_SESSION';
export const AVAILABLE_DELIVERY_COUNTRIES_QUERY_KEY = 'QUERY/AVAILABLE_DELIVERY_COUNTRIES_QUERY_KEY';
export const DEFAULT_EMAIL_ADDRESS = 'support@getjust.eu';
export const MAX_FAILURE_COUNT = 10;
export const MUTATION_LOGIN_KEY = 'MUTATION/LOGIN';
export const MUTATION_UPDATE_PROFILE = 'MUTATION/UPDATE_PROFILE';
export const MUTATION_ESTIMATED_SHIPPING = 'MUTATION/ESTIMATED_SHIPPING';
export const NO_SHIPPING_HANDLE = 'no-shipping';
export const ORDER_REQUEST_QUERY_KEY = 'QUERY/ORDER_REQUEST';
export const REFRESH_TOKEN_MUTATION_KEY = 'MUTATION/REFRESH_TOKEN';
export const REFS_MAP = new Map<string, RefObject<HTMLDivElement | null>>();
export const UPDATE_SOURCES_MUTATION_KEY = 'MUTATION/UPDATE_SOURCES';
export const USER_INFO_QUERY_KEY = 'QUERY/USER_INFO';
export const USER_LOCATION_DETECTION_QUERY_KEY = 'QUERY/USER_LOCATION_DETECTION';
export const VALIDATION_DEBOUNCE = 1500;
export const LOGIN_URL = '/login';
export const REFRESH_URL = '/refresh';
export const USE_AUTH_LEGACY = true;
export const MOST_USED_COUNTRIES = ['FR', 'BE', 'CH', 'GB'];
export * from './commonEmailAddresses';

import { Box, Button, Flex, Menu, MenuButton, MenuItem, MenuList } from '@chakra-ui/react';
import { useCallback, useMemo, useState } from 'react';
import { z } from 'zod';

import type { SVGAttributes } from 'react';

import FlagDE from '$src/components/SVGFlags/FlagDE';
import FlagES from '$src/components/SVGFlags/FlagES';
import FlagFR from '$src/components/SVGFlags/FlagFR';
import FlagIT from '$src/components/SVGFlags/FlagIT';
import FlagUK from '$src/components/SVGFlags/FlagUK';
import { useCustomRouter } from '$src/hooks/client';
import { useTrackAmplitude } from '$src/hooks/client/useTrackAmplitude';
import { ZInboundFormMessage } from '$src/models/state/message';

const languageOptions: Array<{
  label: z.infer<typeof ZInboundFormMessage.shape.language>;
  flagComponent: React.FC<SVGAttributes<SVGElement>>;
}> = [
  { label: 'de', flagComponent: FlagDE },
  { label: 'en', flagComponent: FlagUK },
  { label: 'es', flagComponent: FlagES },
  { label: 'fr', flagComponent: FlagFR },
  { label: 'it', flagComponent: FlagIT },
];

export const LanguageSelection = ({ theme }: { theme: 'light' | 'dark' }) => {
  const { track } = useTrackAmplitude();
  const router = useCustomRouter();

  const [selectedLanguage, setSelectedLanguage] = useState(router.locale);

  const changeLanguage = useCallback(
    (locale: z.infer<typeof ZInboundFormMessage.shape.language>) => {
      setSelectedLanguage(locale);
      router.replace(router.pathname, router.asPath, { locale });
      track('Global attributes', { Language: locale });
    },
    [router, track],
  );

  const selectedLanguageOption = useMemo(() => {
    return languageOptions.find((option) => option.label === selectedLanguage);
  }, [selectedLanguage]);

  return (
    <Menu matchWidth offset={[0, 6]}>
      <MenuButton
        as={Button}
        padding={0}
        minW="80px"
        variant="ghost"
        fontSize="12px"
        overflow="hidden"
        fontWeight="400"
        borderRadius="12px"
        bg="#F7F7F7"
        _hover={{ backgroundColor: '#E0E0E0' }}
        _active={{ backgroundColor: '#E0E0E0' }}
        backdropFilter={theme === 'light' ? 'blur(2.5px)' : undefined}
      >
        <Flex paddingX="16px" paddingY="10px" gap="6px" alignItems="center">
          {selectedLanguageOption?.flagComponent && (
            <selectedLanguageOption.flagComponent
              width="22px"
              style={{ borderRadius: '2px', flexShrink: 0 }}
            />
          )}
          <span style={{ flexGrow: 1 }}>{selectedLanguageOption?.label.toUpperCase()}</span>
        </Flex>
      </MenuButton>
      <MenuList minW="0" borderRadius="12px" padding="8px" zIndex={1500}>
        {languageOptions
          .filter(({ label }) => label !== selectedLanguage)
          .map(({ label, flagComponent: Flag }) => {
            return (
              <MenuItem
                key={label}
                gap="6px"
                fontSize="12px"
                alignItems="center"
                paddingX="8px"
                paddingY="10px"
                borderRadius="8px"
                onClick={() => changeLanguage(label)}
                _hover={{ background: '#E0E0E0' }}
              >
                <Flag style={{ borderRadius: '2px', flexShrink: 0 }} width="22px" />

                {label.toUpperCase()}
              </MenuItem>
            );
          })}
      </MenuList>
    </Menu>
  );
};

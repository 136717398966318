import { Box, Image, Stack, Text } from '@chakra-ui/react';
import { useCallback, useEffect, useMemo, useState } from 'react';

import type { BoxProps } from '@chakra-ui/react';

import { LanguageSelection } from '$src/components/LanguageSelection';
import { useIsDesktop } from '$src/hooks/client/useIsDesktop';
import { usePostMessage } from '$src/hooks/client/usePostMessage';
import { useTrackAmplitude } from '$src/hooks/client/useTrackAmplitude';
import { useShop } from '$src/hooks/queries';
import { useROFormMessageAtom } from '$src/hooks/state';
import { ABTestingCheckoutFeature, useCheckoutFeaturesAtom } from '$src/hooks/state/useCheckoutFeaturesAtom';
import { capturePosthogAnalytics } from '$src/utils';

export const Header = (props: BoxProps) => {
  const { track } = useTrackAmplitude();
  const { postMessage } = usePostMessage();
  const { data: shop } = useShop();
  const { canUseCheckoutFeature } = useCheckoutFeaturesAtom();

  // REMOVE CONDITION AFTER MIGRATION -----------------------------------
  const urlLogoShop = useMemo(
    () =>
      shop?.logo?.includes('https://')
        ? shop?.logo
        : `https://${process.env.NEXT_PUBLIC_LOGOS_BUCKET}.s3.eu-west-3.amazonaws.com/${shop?.logo}`,
    [shop],
  );

  // --------------------------------------------------------------------
  const [logoLoadFailed, setLogoLoadedFailed] = useState(false);
  const [logoSrcSet, setLogoSrcSet] = useState('');
  const { isMobile } = useIsDesktop();
  const message = useROFormMessageAtom();

  useEffect(() => {
    if (!shop) return;
    if (shop.logoVariants?.length) {
      setLogoSrcSet(
        shop.logoVariants
          ?.map((variant) => `${shop.baseMediaUrl}${variant.fileKey} ${variant.name}`)
          .join(', ') ?? '',
      );
    }
  }, [shop]);

  // redirect, add background
  useEffect(() => {
    if (!shop || !message?.redirectMode) return;

    document.body.classList.add('redirect');

    let background;

    const url = shop?.backgroundUrl?.includes('https://')
      ? shop?.backgroundUrl
      : `${shop.baseMediaUrl}${shop?.backgroundUrl}`;

    if (shop?.backgroundUrl) {
      const imgSet = [
        `url(${url}) 1x`,
        ...(shop.bgVariants?.map(
          (variant) => `url(${shop.baseMediaUrl}${variant.fileKey}) ${variant.name}`,
        ) ?? []),
      ].join(', ');

      if (isMobile) {
        if (!canUseCheckoutFeature(ABTestingCheckoutFeature.SHOW_MOBILE_BACKGROUND_IMAGE)) return;

        background = `
            image-set(${imgSet}) center center / cover no-repeat
          `;
      } else {
        background = `image-set(${imgSet}) no-repeat center/cover`;
      }
    } else {
      background = `repeat linear-gradient(0deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.8)), url(${urlLogoShop})`;
    }

    document.body.style.setProperty('--bg-brand', background);
    document.body.classList.add('show-background');
  }, [message?.redirectMode, shop, urlLogoShop, isMobile, canUseCheckoutFeature]);

  const onBack = useCallback(() => {
    capturePosthogAnalytics('LEAVE');
    track('Checkout form closed');
    if (message?.redirectMode && message.origin) {
      window.location.replace(message.origin);
      return;
    }
    return postMessage({
      emitter: 'JUST_FORM',
      action: 'CLOSE',
    });
  }, [message?.origin, message?.redirectMode, postMessage, track]);

  const showMobileBackground =
    canUseCheckoutFeature(ABTestingCheckoutFeature.SHOW_MOBILE_BACKGROUND_IMAGE) && isMobile;

  return (
    <Box
      alignItems="start"
      justifyContent="space-between"
      {...props}
      mb="2px"
      px={4}
      py={3}
      w="100%"
      display="flex"
    >
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        w="full"
        h={!isMobile ? 'full' : undefined}
      >
        <Box width="33%" display="flex" justifyContent="start">
          {showMobileBackground ? (
            <ArrowBackIcon onBack={onBack} />
          ) : (
            <Box ml={isMobile ? '4' : '6'} pt={isMobile ? '3' : '5'}>
              <ArrowBackIcon onBack={onBack} />
            </Box>
          )}
        </Box>

        <Box flexGrow={1} height="100%" display="flex" justifyContent="center">
          {!logoLoadFailed && shop?.logo ? (
            <Image
              maxH="100%"
              h={isMobile ? '1.5rem' : '2.5rem'}
              alt="logo shop"
              textAlign="center"
              src={urlLogoShop}
              srcSet={logoSrcSet}
              onError={() => setLogoLoadedFailed(true)}
            />
          ) : (
            <Text textAlign="center" py="4">
              {shop?.name ?? ''}
            </Text>
          )}
        </Box>

        <Box width="33%" display="flex" justifyContent="end" alignItems="center">
          {showMobileBackground ? (
            <LanguageSelection theme="light" />
          ) : (
            <Box mr={isMobile ? '1' : '3'} pt={isMobile ? '1' : '3'}>
              <LanguageSelection theme="dark" />
            </Box>
          )}
        </Box>
      </Stack>
    </Box>
  );
};

function ArrowBackIcon({ onBack }: { onBack: () => void }) {
  return (
    <Box w="24px" h="24px" onClick={onBack} cursor="pointer" _hover={{ bg: 'none' }}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={24}
        height={24}
        fill="none"
        role="img"
        aria-label="Close"
      >
        <path
          fill="#000"
          fillOpacity={0.85}
          d="M3 12.402c0 .245.107.47.303.655l6.484 6.474c.195.186.4.274.635.274.478 0 .86-.352.86-.84a.855.855 0 0 0-.245-.615L8.85 16.123l-3.868-3.525-.205.478 3.145.195h12.353c.508 0 .86-.36.86-.869 0-.507-.352-.869-.86-.869H7.922l-3.145.196.205.488L8.85 8.682l2.187-2.227a.871.871 0 0 0 .244-.615c0-.488-.38-.84-.86-.84-.233 0-.439.078-.653.293l-6.465 6.455a.895.895 0 0 0-.303.654Z"
        />
      </svg>
    </Box>
  );
}

import { getStorage } from '@getjust/commons';
import { useRouter } from 'next/router';
import { useCallback, useEffect, useState } from 'react';

import { useCartIdUpdater } from '$hooks/client/useCartIdUpdater';
import { useFormMessageAtom } from '$hooks/state/useFormMessageAtom';
import { InboundFormMessageAtom } from '$src/business/atoms/message';
import { base64DecodeUnicode } from '$src/utils';

export const useRedirectSetData = () => {
  const router = useRouter();
  const { onFormMessageChange } = useFormMessageAtom();
  const [initialData, setInitialData] = useState(false);
  const updateCartId = useCartIdUpdater();

  const handleRedirectData = useCallback(
    (data: InboundFormMessageAtom) => {
      let existingCartId: string | undefined;
      if (data.cart.id) {
        existingCartId = data.cart.id;
        updateCartId(data.cart.id);
      } else {
        existingCartId = getStorage('localStorage', `just_session_id_${data.shopId}`) ?? undefined;
      }
      onFormMessageChange({ ...data, cart: { ...data.cart, id: existingCartId ?? undefined } });
      setInitialData(true);
    },
    [onFormMessageChange, updateCartId],
  );

  useEffect(() => {
    if (initialData) return;
    if (router?.query?.data || router?.query?.passthrough) {
      try {
        if (router?.query?.passthrough) {
          fetch(`${process.env.NEXT_PUBLIC_PASSTHROUGH_URL}/${router?.query?.passthrough}`)
            .then((response) => response.json())
            .then((data) => {
              handleRedirectData(JSON.parse(base64DecodeUnicode(data.record.data)));
            });
        } else {
          handleRedirectData(JSON.parse(base64DecodeUnicode(router?.query?.data as string)));
        }
      } catch (e) {
        console.error('[APP] unhandled searchParams data');
      }
    }
  }, [handleRedirectData, initialData, onFormMessageChange, router?.query?.data, router?.query?.passthrough]);
};

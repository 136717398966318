'use client';

import { datadogLogs } from '@datadog/browser-logs';
import { datadogRum } from '@datadog/browser-rum';
import { reactPlugin } from '@datadog/browser-rum-react';

export const initDatadog = () => {
  if (!process?.env?.NEXT_PUBLIC_DATADOG_APPLICATION_ID || !process?.env?.NEXT_PUBLIC_DATADOG_CLIENT_TOKEN) {
    return;
  }

  // Ensure it runs only on the client side
  if (typeof window === 'undefined') return;

  // Initialize Datadog RUM
  datadogRum.init({
    applicationId: process?.env?.NEXT_PUBLIC_DATADOG_APPLICATION_ID,
    clientToken: process?.env?.NEXT_PUBLIC_DATADOG_CLIENT_TOKEN,
    site: 'datadoghq.eu',
    service: 'checkout-form-frontend',
    env: process?.env?.NEXT_PUBLIC_ENV || 'development',
    sessionSampleRate: 100, // Collects all sessions (adjust as needed)
    sessionReplaySampleRate: 20, // Enables session replay for 20% of users
    defaultPrivacyLevel: 'mask-user-input',
    plugins: [reactPlugin({ router: false })],
    trackUserInteractions: true,
    trackResources: true,
    trackLongTasks: true,
  });

  // Initialize Datadog RUM
  datadogLogs.init({
    clientToken: process?.env?.NEXT_PUBLIC_DATADOG_CLIENT_TOKEN,
    site: 'datadoghq.eu',
    service: 'checkout-form-frontend',
    env: process?.env?.NEXT_PUBLIC_ENV || 'development',
    forwardErrorsToLogs: true,
    sessionSampleRate: 100,
  });

  datadogRum.startSessionReplayRecording();
};

import { Box } from '@chakra-ui/react';

import { FormLoader } from '$components/FormLoader';
import { PaidLoader } from '$components/PaidLoader';
import { ToastError } from '$components/ToastError';
import { useIsDesktop } from '$src/hooks/client/useIsDesktop';
import { ABTestingCheckoutFeature, useCheckoutFeaturesAtom } from '$src/hooks/state/useCheckoutFeaturesAtom';

interface LoadingWrapperProps {
  children: React.ReactNode;
  isLoading: boolean;
  isPaidLoading: boolean;
}

export const LoadingWrapper = ({ isLoading, isPaidLoading, children }: LoadingWrapperProps) => {
  const { canUseCheckoutFeature } = useCheckoutFeaturesAtom();
  const { isMobile } = useIsDesktop();

  return (
    <>
      {isLoading && <FormLoader />}
      {isPaidLoading && <PaidLoader />}
      <ToastError />
      <Box
        display={isLoading ? 'none' : isMobile ? 'flex' : 'block'}
        overflowY={isLoading ? 'hidden' : isMobile ? 'hidden' : 'auto'}
        flexDirection={isMobile ? 'column' : 'row'}
        h="full"
        bg={
          canUseCheckoutFeature(ABTestingCheckoutFeature.SHOW_MOBILE_BACKGROUND_IMAGE) && isMobile
            ? undefined
            : 'white'
        }
      >
        {children}
      </Box>
    </>
  );
};

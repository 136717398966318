/**
 * Check if a value is a valid enum value
 *
 * @example
 * const enumType = {
 *   TEST: 'test',
 *   CONTROL: 'control',
 * }
 * const isEnumValue = isEnum('test', enumType); // true
 */
export const isEnum = <T extends string>(
  value: string | undefined,
  enumType: Record<string, T>,
): value is T => {
  if (!value) return false;

  return Object.values(enumType).includes(value as T);
};

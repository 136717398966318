import { is } from 'ramda';
import { useEffect } from 'react';

import { useFormMessageAtom } from '$hooks/state';
import { ZInboundFormMessage } from '$src/models/state/message';
import { capturePosthogAnalytics } from '$src/utils';

export function useMessageListenner() {
  const { onFormMessageChange } = useFormMessageAtom();

  useEffect(() => {
    window.parent.postMessage(
      JSON.stringify({
        emitter: 'JUST_FORM',
        ready: true,
      }),
      '*',
    );
  }, []);

  useEffect(() => {
    const messageHandler = (event: MessageEvent<any>) => {
      const { data } = event;
      if (is(String, data) && data.includes('JUST_BUTTON')) {
        const parsed = JSON.parse(data);
        if (parsed?.emitter === 'JUST_BUTTON') {
          const safeData = ZInboundFormMessage.safeParse(parsed);
          if (safeData.success) {
            onFormMessageChange(safeData.data);
          } else {
            console.error('Error while parsing form message', {
              error: safeData.error,
              context: 'postMessage',
            });
          }
          capturePosthogAnalytics('button_message', safeData);
        }
      }
    };

    window.addEventListener('message', messageHandler, true);

    return () => window.removeEventListener('message', messageHandler);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps
}

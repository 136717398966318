import { formatPrice } from '@getjust/commons';
import { Cart } from '@getjust/leaf';
import Currency from 'currency.js';
import { useTranslation } from 'next-i18next';
import { useCallback, useEffect, useMemo, useState } from 'react';

import type { StackProps } from '@chakra-ui/react';
import type { UpsellProduct } from '@getjust/gateway';

import Coupon from '$components/SvgLogos/Coupon';
import { useAddDiscount, useDeleteDiscount, useDeleteLineItems } from '$hooks/mutations';
import { useCart, useShop } from '$hooks/queries';
import { useIsDesktop } from '$src/hooks/client/useIsDesktop';
import { useTrackAmplitude } from '$src/hooks/client/useTrackAmplitude';
import { useAddLineItems } from '$src/hooks/mutations/useAddLineItems';
import { useClearGiftCards } from '$src/hooks/mutations/useClearGiftCards';
import { useUpdateLineItems } from '$src/hooks/mutations/useUpdateLineItems';
import { useUpsellProducts } from '$src/hooks/queries/useUpsellProducts';
import { useCheckoutFeaturesAtom } from '$src/hooks/state/useCheckoutFeaturesAtom';
import { useCorrectionsAtom } from '$src/hooks/state/useCorrectionsAtom';
import { useEstimatedShippingAtom } from '$src/hooks/state/useEstimatedShippingAtom';
import { useRecommendedDiscountsAtom } from '$src/hooks/state/useRecommendedDiscountsAtom';
import { capturePosthogAnalytics } from '$src/utils';

interface CartDetailProps extends StackProps {
  isUpsellDisabled?: boolean;
  showTotal?: boolean;
}

const CartDetail = ({ isUpsellDisabled, showTotal = true, ...props }: CartDetailProps) => {
  const { mutateAsync: updateLineItems } = useUpdateLineItems();
  const { canUseCheckoutFeature } = useCheckoutFeaturesAtom();
  const { error: discountError, addDiscount, status } = useAddDiscount();
  const { mutateAsync: deleteDiscount } = useDeleteDiscount();
  const { mutateAsync: clearGiftCards } = useClearGiftCards();
  const { t } = useTranslation('common');
  const { data: cart } = useCart();
  const { track } = useTrackAmplitude();
  const { corrections, initCorrections, clearCorrections } = useCorrectionsAtom();
  const { estimatedShipping } = useEstimatedShippingAtom();
  const { isMobile } = useIsDesktop();
  const [discountValue, setDiscountValue] = useState('');
  const { data: shop } = useShop();
  const { recommendedDiscountsAtom: recommendedDiscount } = useRecommendedDiscountsAtom();

  const onDiscountAddClick = useCallback(
    async (code: string, isRecommended = false) => {
      capturePosthogAnalytics('discount_code_apply');
      track('Add discount clicked');
      if (isRecommended) {
        track('Finder clicked', { 'Code clicked': code });
      }
      await addDiscount({
        code: code.trim(),
      });
    },
    [addDiscount, track],
  );

  const getLeftQuantityTitle = useCallback(
    (quantityAvailable: number | undefined, isAvailable: boolean): string => {
      if (!quantityAvailable && !isAvailable) return t('errors.out_of_stock');
      if (!quantityAvailable && isAvailable) return '';
      if (quantityAvailable === 1) return t('commons.leftQuantity', { count: quantityAvailable });
      return t('commons.leftQuantity_plurials', { count: quantityAvailable });
    },
    [t],
  );

  const onDeleteDiscount = useCallback((code: string) => deleteDiscount({ code }), [deleteDiscount]);
  const onGiftCardRemove = useCallback(() => clearGiftCards(), [clearGiftCards]);

  const onUpdateItem = useCallback(
    async ({
      newQuantity,
      id,
      productId,
      variantId,
      customizationId,
    }: {
      id: string;
      productId: string | undefined;
      variantId: string | undefined;
      newQuantity: number;
      customizationId: string | undefined;
    }) => {
      const res = await updateLineItems({
        lineItems: [
          {
            id,
            productId,
            variantId,
            quantity: newQuantity,
            customizationId,
          },
        ],
      });
      if (!res.data.corrections?.length) {
        clearCorrections();
      } else {
        initCorrections(res.data.corrections);
      }
    },
    [updateLineItems, clearCorrections, initCorrections],
  );

  // Upsell
  const { data: upsellProducts } = useUpsellProducts(isUpsellDisabled);
  const { mutateAsync: addLineItems } = useAddLineItems();
  const [filteredUpsellProducts, setFilteredUpsellProducts] = useState<UpsellProduct[]>([]);

  useEffect(() => {
    setFilteredUpsellProducts(upsellProducts ?? []);
  }, [upsellProducts]);

  useEffect(() => {
    if (!isUpsellDisabled) {
      track('Cross sell opened');
    }
    if (cart?.corrections?.length) {
      initCorrections(cart?.corrections);
    }
    return () => {
      clearCorrections();
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const onUpsellClick = useCallback(
    async (productToAdd: UpsellProduct) => {
      await addLineItems({
        lineItems: [
          {
            productId: productToAdd.productId,
            variantId: productToAdd.variantId,
            quantity: 1,
          },
        ],
      });

      track('Cross sell product added');

      const index = filteredUpsellProducts?.findIndex(
        (e: UpsellProduct) =>
          e.variantId === productToAdd.variantId ||
          !!e.variants.find((v: string) => v === productToAdd.variantId),
      );

      if (index !== -1) {
        const copy = [...filteredUpsellProducts];
        copy.splice(index, 1);
        setFilteredUpsellProducts(copy);
      }
    },
    [addLineItems, filteredUpsellProducts, track],
  );

  // Products
  const { mutateAsync: deleteLineItems } = useDeleteLineItems();
  const onDeleteProduct = useCallback(
    async ({ id }: { id: string }) => {
      await deleteLineItems({ lineItemIds: [id] });
    },
    [deleteLineItems],
  );
  const shippingMessage = useMemo(() => {
    if (
      cart?.shipping?.selected &&
      cart?.shipping?.address &&
      cart?.shipping?.address?.firstName !== 'Just' && // JUST Users in Prestashop
      cart?.shipping?.address?.lastName !== 'Anonymous' && // mandatory to have estimated
      cart?.shipping?.address?.address1 !== '142 AVENUE LEDRU ROLLIN' // shipping in prestashop
    ) {
      return cart?.totalShipping === 0
        ? t('notAuthed.freeShipping')
        : `${formatPrice(cart?.totalShipping, {
            currency: cart?.currency ? cart.currency : 'EUR',
          })}`;
    }
    if (estimatedShipping) {
      return estimatedShipping.amount === 0
        ? t('notAuthed.estimatedFree')
        : `${t('notAuthed.estimated', {
            amount: formatPrice(estimatedShipping.amount, {
              currency: cart?.currency ?? 'EUR',
            }),
          })}`;
    }

    return t('commons.calculating');
  }, [cart, t, estimatedShipping]);

  const totalMessage = useMemo(() => {
    if (estimatedShipping && !cart?.shipping?.address) {
      return {
        label: t('commons.total'),
        value: formatPrice(Currency(cart?.totalAmount ?? 0).add(estimatedShipping.amount).value, {
          currency: cart?.currency ?? 'EUR',
        }),
      };
    }
    return {
      label: t('commons.total'),
      value: formatPrice(cart?.totalAmount ?? 0, { currency: cart?.currency ?? 'EUR' }),
    };
  }, [cart, t, estimatedShipping]);

  const recommendedDiscountCodes = useMemo(() => {
    const codes = recommendedDiscount?.map((el) => el.code) ?? [];
    if (codes.length) {
      track('Finder triggered', {
        'Nb codes': codes.length,
        codes,
      });
    }
    return codes;
  }, [recommendedDiscount]);

  const isDiscountHidden = canUseCheckoutFeature('hide_discount_input');

  return (
    <Cart
      isDiscountHidden={isDiscountHidden}
      onDiscountAddClick={onDiscountAddClick}
      onDiscountRemove={onDeleteDiscount}
      onGiftCardRemove={onGiftCardRemove}
      discountApplyLabel={t('buttons.add_discount')}
      discountInputLabel={t('commons.discount_code')}
      discountFinderLabel={t('commons.discount_finder')}
      recommendedDiscountCodes={recommendedDiscountCodes}
      discountErrorLabel={
        status === 'error'
          ? (discountError.message ??
            (t('errors.commons.unknown_discount', { code: discountValue }) as string))
          : undefined
      }
      total={showTotal ? totalMessage : { label: '', value: '' }}
      subTotal={{
        label: !cart?.taxIncluded ? t('commons.subtotalHT') : t('commons.subtotal'),
        value: formatPrice((cart?.subTotal ?? 0) + (cart?.totalSale ?? 0), {
          currency: cart?.currency ?? 'EUR',
        }),
      }}
      shipping={{
        label: t('commons.shipping_method'),
        value: shippingMessage,
      }}
      tax={
        !cart?.taxIncluded
          ? {
              value: cart?.taxReady
                ? formatPrice(cart?.totalTax ?? 0, {
                    currency: cart?.currency ?? 'EUR',
                  })
                : t('commons.calculating'),
              label: t('commons.tax'),
            }
          : undefined
      }
      products={(cart?.lineItems ?? []).map((lineItem) => ({
        ...lineItem,
        price: formatPrice(lineItem?.totalAmount, { currency: cart?.currency ?? 'EUR' }),
        variant: lineItem?.variants,
        image: lineItem?.imageUrl ?? '',
      }))}
      totalSale={
        cart?.totalSale
          ? {
              label: t('commons.sale'),
              value: formatPrice(cart?.totalSale ?? 0, {
                currency: cart?.currency ?? 'EUR',
              }),
            }
          : undefined
      }
      totalDiscount={
        cart?.totalDiscount
          ? {
              label: t('commons.discount'),
              value: formatPrice(cart?.totalDiscount ?? 0, {
                currency: cart?.currency ?? 'EUR',
              }),
            }
          : undefined
      }
      totalDuties={
        cart?.totalDuties
          ? {
              label: t('commons.duties'),
              value: formatPrice(cart?.totalDuties ?? 0, {
                currency: cart?.currency ?? 'EUR',
              }),
            }
          : undefined
      }
      discounts={cart?.discounts ?? []}
      giftCards={cart?.giftCards ?? []}
      totalGiftCard={
        cart?.totalGiftCard
          ? {
              label: t('commons.gift_card'),
              value: formatPrice(cart?.totalGiftCard ?? 0, {
                currency: cart?.currency ?? 'EUR',
              }),
            }
          : undefined
      }
      upsellProducts={filteredUpsellProducts}
      onUpsellClick={onUpsellClick}
      upsellLabel={t('upsell.title') as string}
      onDeleteProduct={onDeleteProduct}
      deleteLabel={t('buttons.delete') as string}
      isUpsellDisabled={isUpsellDisabled}
      isQuantityDisabled={!shop?.features?.includes('quantity_selector')}
      isStockDisabled={!shop?.features?.includes('product_stock_indicator')}
      currency={cart?.currency ?? 'EUR'}
      shouldFocusDiscountField={false}
      updateQuantity={onUpdateItem}
      taxIncludedTitle={t('commons.taxIncludedTitle') as string}
      leftQuantityTitles={
        cart?.lineItems?.map((lineItem) =>
          getLeftQuantityTitle(lineItem.quantityAvailable, lineItem.isAvailable),
        ) ?? ['']
      }
      corrections={corrections ?? []}
      Icon={Coupon}
      availabilityMessage={corrections?.length ? t('errors.availability_warning') : ''}
      updatedCartInformation={corrections?.length ? t('info.cart_updated') : ''}
      outOfStockMessage={corrections?.length ? t('errors.out_of_stock') : ''}
      isMobile={isMobile}
      setDiscountValue={setDiscountValue}
      {...props}
    />
  );
};

export default CartDetail;

import { useToast } from '@chakra-ui/react';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { useTranslation } from 'next-i18next';

import type { Cart, ConfirmCartResult } from '@getjust/gateway';

import { useShop } from '$hooks/queries';
import { usePaymentStatusAtom, useROFormMessageAtom } from '$hooks/state';
import { CART_QUERY_KEY, USE_AUTH_LEGACY } from '$src/constants';
import { useAuth } from '$src/hooks/auth';
import { useCustomRouter } from '$src/hooks/client';
import { useBaseApiUrl } from '$src/hooks/client/useBaseApiUrl';
import { usePostMessage } from '$src/hooks/client/usePostMessage';
import { useTrackAmplitude } from '$src/hooks/client/useTrackAmplitude';
import { useCorrectionsAtom } from '$src/hooks/state/useCorrectionsAtom';
import { useErrorAtom } from '$src/hooks/state/useErrorAtom';
import { gateway } from '$src/http';
import { ConfirmInput } from '$src/pages/api/[shopId]/[cartId]/confirm';
import { _postMessage, capturePosthogAnalytics } from '$src/utils';

export const CREATE_ORDER_MUTATION_KEY = 'MUTATION/CREATE_ORDER';

export const useCreateOrder = () => {
  const { initCorrections, clearCorrections } = useCorrectionsAtom();
  const { onPaymentStatusChange } = usePaymentStatusAtom();
  const { data: shop } = useShop();
  const message = useROFormMessageAtom();
  const { t, i18n } = useTranslation('common');
  const baseUrl = useBaseApiUrl();
  const { setIsAuthenticated, setLegacyState } = useAuth();
  const { postMessage } = usePostMessage();
  const queryClient = useQueryClient();
  const { onErrorChange } = useErrorAtom();
  const { track } = useTrackAmplitude();
  const toast = useToast();
  const router = useCustomRouter();

  return useMutation({
    mutationFn: (input: ConfirmInput) => gateway.post<ConfirmCartResult>(`${baseUrl}/confirm`, input),
    mutationKey: [CREATE_ORDER_MUTATION_KEY, baseUrl],
    onError: (error: AxiosError<ConfirmCartResult>, variables) => {
      switch (error?.response?.data?.outcome) {
        case 'notConfirmed':
          if (error.response.data?.reason === 'merchantCorrections') {
            const updatedCart = error.response?.data.cart;
            if (updatedCart) {
              queryClient.setQueryData<Cart>([CART_QUERY_KEY, error.response.data.cart.id], updatedCart);
              if (!updatedCart.corrections?.length) {
                clearCorrections();
              } else {
                initCorrections(updatedCart.corrections);
              }
            }
          }
          if (error.response.data?.reason === 'priceMismatch') {
            track('price_miss_match', {
              variantsIds: JSON.stringify(message?.cart?.lineItems?.map((item) => item.variantId) ?? []),
            });
            toast({
              description: t('errors.commons.priceMismatch'),
              status: 'error',
              position: 'top',
              duration: 10000,
              isClosable: true,
            });

            const data = error.response.data as { url?: string } & ConfirmCartResult;
            if (data.url) {
              setTimeout(() => {
                if (message?.redirectMode && message.origin) {
                  location.href = data.url ?? '';
                } else {
                  _postMessage({
                    emitter: 'JUST_FORM',
                    action: 'NAVIGATE',
                    orderUrl: data.url,
                  });
                }
              }, 5000);
            }
          }
          if (error.response.data.reason === 'threedSecured') {
            onPaymentStatusChange({
              display: true,
              url: error.response.data.nextAction ?? '',
              cartId: error.response.data.cartId,
              opened: true,
            });
          }
          if (['cannotAddSource', 'paymentError'].indexOf(error.response.data.reason) !== -1) {
            const errorCode =
              (error.response.data.reason === 'paymentError'
                ? error.response.data?.code
                : // @ts-expect-error - error
                  error.response.data.error?.error?.code) ?? 'card_declined';
            const titleError = `errors.stripe.${errorCode}.title`;
            const sentenceError = `errors.stripe.${errorCode}.sentence`;
            const genericTitleError = `errors.stripe.generic_decline.title`;
            const genericSentenceError = `errors.stripe.generic_decline.sentence`;
            onErrorChange({
              title: i18n.exists(titleError) ? t(titleError) : t(genericTitleError),
              sentence: i18n.exists(sentenceError) ? t(sentenceError) : t(genericSentenceError),
            });
            if (['expired_card', 'incorrect_cvc'].includes(errorCode)) {
              track('Order creation executed', {
                output: 'Wrong date/crypto',
                paymentMethod: variables.paymentMethod,
              });
            } else {
              track('Order creation executed', {
                output: errorCode,
                paymentMethod: variables.paymentMethod,
              });
            }
          }
          break;
        default:
          capturePosthogAnalytics('CREATE_ORDER_FAILED', {
            seller: shop?.name,
            source: message!.source,
          });
          break;
      }
    },
    onSuccess: async () => {
      postMessage({
        emitter: 'JUST_FORM',
        action: 'CLEAR_SESSION_ID',
      });
    },
    onSettled: (response, error) => {
      if (error?.response?.data?.cart?.id) {
        queryClient.setQueryData<Cart>(
          [CART_QUERY_KEY, error.response.data.cart.id],
          error?.response?.data?.cart,
        );
      }

      if (response?.data?.cart?.id) {
        queryClient.setQueryData<Cart>([CART_QUERY_KEY, response.data.cart.id], response?.data?.cart);
      }

      if (
        // @ts-expect-error - expected
        response?.data?.login ||
        // @ts-expect-error - expected
        error?.response?.data?.login
      ) {
        if (USE_AUTH_LEGACY) {
          setLegacyState({
            accessToken:
              // @ts-expect-error always defined
              response?.data?.login?.accessToken ?? error?.response?.data?.login?.accessToken ?? '',
            refreshToken:
              // @ts-expect-error always defined
              response?.data?.login?.refreshToken ?? error?.response?.data?.login?.refreshToken ?? '',
          });
        }
        setIsAuthenticated(true);
      }
      switch (response?.data?.outcome) {
        case 'confirmed':
          onPaymentStatusChange({
            display: false,
            url: undefined,
            cartId: response.data.cartId,
            opened: true,
          });
          break;
      }
    },
  });
};

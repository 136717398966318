import { useAtom } from 'jotai';
import { useCallback } from 'react';

import { checkoutFeaturesAtom } from '$src/business/atoms/checkout-features';
import { Shop } from '$src/hooks/queries';
import { isEnum } from '$src/utils/isEnum';

enum ABTestingGroup {
  TEST = 'test',
  CONTROL = 'control',
}

export enum ABTestingCheckoutFeature {
  SHOW_MOBILE_BACKGROUND_IMAGE = 'show_mobile_background_image',
}

export function useCheckoutFeaturesAtom() {
  const [features, setFeatures] = useAtom(checkoutFeaturesAtom);

  const filterFeatureWithABTesting = useCallback((features: string[]) => {
    return features.filter((feature) => {
      const devFeatures = process?.env?.NEXT_PUBLIC_AB_TESTING_FEATURES?.split(',') ?? [];

      // keep normal features
      if (!isEnum(feature, ABTestingCheckoutFeature)) {
        return true;
      }

      // AB testing features
      const testingGroup = devFeatures?.includes(feature)
        ? ABTestingGroup.TEST
        : Math.random() < 0.5
          ? ABTestingGroup.TEST
          : ABTestingGroup.CONTROL;

      return testingGroup === ABTestingGroup.TEST;
    });
  }, []);

  const onCheckoutFeaturesChange = useCallback(
    (shop: Shop) => {
      if (!shop || !Array.isArray(shop.features)) {
        console.warn('Invalid shop features:', shop?.features);
        return;
      }

      setFeatures(filterFeatureWithABTesting(shop.features));
    },
    [setFeatures, filterFeatureWithABTesting],
  );

  const canUseCheckoutFeature = useCallback(
    (feature: string) => {
      return new Set(features).has(feature);
    },
    [features],
  );

  return { features, canUseCheckoutFeature, onCheckoutFeaturesChange };
}

import { useMutation, useQueryClient } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { useRef } from 'react';

import type { Cart } from '@getjust/gateway';

import { CART_QUERY_KEY } from '$src/constants';
import { useBaseApiUrl } from '$src/hooks/client/useBaseApiUrl';
import { useCartIdUpdater } from '$src/hooks/client/useCartIdUpdater';
import { useCart } from '$src/hooks/queries';
import { useCorrectionsAtom } from '$src/hooks/state/useCorrectionsAtom';
import { gateway } from '$src/http';
import { UpdateShippingMethodInput } from '$src/pages/api/[shopId]/[cartId]/update-shipping-method';

export const UPDATE_SHIPPING_METHOD_MUTATION_KEY = 'MUTATION/UPDATE_SHIPPING_METHOD';

export const useUpdateShippingMethod = () => {
  const { initCorrections } = useCorrectionsAtom();
  const baseUrl = useBaseApiUrl();
  const updateCartId = useCartIdUpdater();
  const queryClient = useQueryClient();
  const { data: cart } = useCart();
  const abortControllerRef = useRef<AbortController>(null);

  const mutation = useMutation({
    mutationFn: (input: UpdateShippingMethodInput) => {
      abortControllerRef.current = new AbortController();
      const signal = abortControllerRef.current.signal;
      return gateway.post<Cart>(`${baseUrl}/update-shipping-method`, input, { signal });
    },
    mutationKey: [UPDATE_SHIPPING_METHOD_MUTATION_KEY, baseUrl],
    onMutate: async (variables) => {
      await queryClient.cancelQueries({ queryKey: [CART_QUERY_KEY] });
      const previousCart = queryClient.getQueryData<Cart | undefined>([CART_QUERY_KEY, cart?.id]);
      queryClient.setQueryData<Cart | undefined>([CART_QUERY_KEY, cart?.id], (oldCart): Cart | undefined => {
        if (!oldCart) return oldCart;
        const selected = oldCart.shipping.methods?.find((method) => method.id === variables.shippingMethodId);
        if (!selected) return oldCart;
        return {
          ...oldCart,
          shipping: {
            ...oldCart.shipping,
            selected: [{ ...selected }],
          },
        };
      });

      return {
        previousCart,
      };
    },
    onError: (error, _shippinMethod, context) => {
      if (error instanceof AxiosError && error.code !== 'ERR_CANCELED') {
        // we do not reset query data if the error is due to an abort, to avoid glitch (exemple in shipping methods)
        queryClient.setQueryData<Cart>([CART_QUERY_KEY, context?.previousCart?.id], context?.previousCart);
      }
    },
    onSuccess: ({ data }, context) => {
      updateCartId(data?.id);
      if (data.shipping.selected?.[0]?.id !== context?.shippingMethodId) {
        // eslint-disable-next-line no-console
        console.log('!!!!!!!!! SHIPPING METHOD ID MISMATCH !!!!!!!!!!!!!!');
        mutation.mutate({ shippingMethodId: context?.shippingMethodId });
        queryClient.setQueryData<Cart>([CART_QUERY_KEY, data.id], data);
      } else {
        queryClient.setQueryData<Cart>([CART_QUERY_KEY, data.id], data);
        if (data.corrections?.length) {
          initCorrections(data.corrections);
        }
      }
    },
  });

  const cancel = () => {
    if (abortControllerRef.current) {
      abortControllerRef.current?.abort();
    }
  };

  return {
    ...mutation,
    cancel,
  };
};

export const COMMON_EMAIL_ADDRESSES = [
  'gmail.com',
  'hotmail.fr',
  'hotmail.com',
  'orange.fr',
  'yahoo.fr',
  'icloud.com',
  'outlook.fr',
  'wanadoo.fr',
  'live.fr',
  'free.fr',
  'laposte.net',
  'yahoo.com',
  'sfr.fr',
  'outlook.com',
  'neuf.fr',
  'me.com',
  'aol.com',
  'msn.com',
  'bluewin.ch',
  'bbox.fr',
  'gmx.de',
  'web.de',
  'ymail.com',
  'gmx.fr',
  'protonmail.com',
];

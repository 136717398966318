import { Box, Container, Divider, Flex } from '@chakra-ui/react';
import { keyframes } from '@emotion/react';
import { JustSkeleton } from '@getjust/leaf';
import { useEffect, useMemo, useRef, useState } from 'react';

import CartDetail from '$components/CartDetail';
import { CartSummary } from '$components/CartSummary';
import { Header } from '$components/Header';
import { LoadingWrapper } from '$components/LoadingWrapper';
import { ProgressBar } from '$components/ProgressBar';
import { REFS_MAP } from '$src/constants';
import { useIsDesktop } from '$src/hooks/client/useIsDesktop';
import { useCart, useShop } from '$src/hooks/queries';
import { ABTestingCheckoutFeature, useCheckoutFeaturesAtom } from '$src/hooks/state/useCheckoutFeaturesAtom';
import { useLoadingAtom, usePaidLoadingAtom } from '$src/hooks/state/useLoadingAtom';
import { isInIframe } from '$src/utils/inIframe';

const collapseHeader = keyframes`
  0% { height: 8.5rem; }
  100% { height: 4rem; }
`;

const expandHeader = keyframes`
  0% { height: 4rem; }
  100% { height: 8.5rem; }
`;

const collapseCartSummary = keyframes`
  from { height: 100%; }
  to { height: 0; }
`;

const expandCartSummary = keyframes`
  from { height: 0; }
  to { height: 100%; }
`;

const fadeInCartSummary = keyframes`
  from { opacity: 1; }
  to { opacity: 0; }
`;

const fadeOutCartSummary = keyframes`
  from { opacity: 0; }
  to { opacity: 1; }
`;

type Props = {
  children: React.ReactNode;
};

export const Layout = ({ children }: Props) => {
  const { isLargerThan750, isLargerThan900, isMobile } = useIsDesktop();
  const { loading } = useLoadingAtom();
  const { paidLoading } = usePaidLoadingAtom();
  const { data: cart, status: useCartStatus, isLoading: cartLoading } = useCart();
  const { data: shop } = useShop();
  const { canUseCheckoutFeature } = useCheckoutFeaturesAtom();

  const portalRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    // update tab title
    if (shop?.name) {
      document.title = shop?.name;
    }
  }, [shop?.name]);

  const inIframe = useMemo(() => {
    return isInIframe();
  }, []);

  useEffect(() => {
    if (portalRef.current) {
      REFS_MAP.set('portal', portalRef);
    }
  }, []);

  useEffect(() => {
    // slide animation for mobiles
    if (!portalRef.current) return;
    const myTimeout = setTimeout(() => {
      portalRef.current?.classList.add('slideIn');
    }, 200);
    return () => clearTimeout(myTimeout);
  }, []);

  const [scrollPercentage, setScrollPercentage] = useState<number>(0);
  const mainRef = useRef<HTMLDivElement>(null);
  const [isHeaderCollapsed, setIsHeaderCollapsed] = useState(false);
  const [isCartSummaryCollapsed, setIsCartSummaryCollapsed] = useState(false);

  useEffect(() => {
    const scrollableDiv = mainRef.current;
    if (!scrollableDiv) return;

    function handleScroll() {
      if (!scrollableDiv) return;
      const { scrollTop, scrollHeight, clientHeight } = scrollableDiv;
      const maxScroll = scrollHeight - clientHeight;
      const scrolled = (scrollTop / maxScroll) * 100;
      setScrollPercentage(scrolled);

      setIsHeaderCollapsed(scrolled >= 2);
      setIsCartSummaryCollapsed(scrolled >= 2);
    }

    scrollableDiv.addEventListener('scroll', handleScroll, { passive: true });
    return () => scrollableDiv.removeEventListener('scroll', handleScroll);
  }, []);

  const showMobileBackground =
    canUseCheckoutFeature(ABTestingCheckoutFeature.SHOW_MOBILE_BACKGROUND_IMAGE) && isMobile;

  const showProgressBar = useMemo(() => {
    if (loading || cartLoading) return false;
    if (!isMobile) return scrollPercentage >= 10;

    return showMobileBackground ? scrollPercentage >= 6 : scrollPercentage >= 3;
  }, [scrollPercentage, showMobileBackground, isMobile, loading, cartLoading]);

  const headerHeight = useMemo(() => {
    if (!isMobile) return '3.75rem';
    if (!showMobileBackground) return '4rem';

    return '8.5rem';
  }, [isMobile, showMobileBackground]);

  const animations = useMemo(() => {
    if (!showMobileBackground) return { header: {}, cartSummary: { height: undefined, opacity: undefined } };

    const headerAnimation = isHeaderCollapsed
      ? `${collapseHeader} 200ms ease-out forwards`
      : `${expandHeader} 200ms ease-out forwards`;

    const cartSummaryAnimations = {
      height: isCartSummaryCollapsed
        ? `${collapseCartSummary} 200ms ease-out forwards`
        : `${expandCartSummary} 200ms ease-out forwards`,

      opacity: isCartSummaryCollapsed
        ? `${fadeInCartSummary} 200ms ease-out forwards`
        : `${fadeOutCartSummary} 200ms ease-out forwards`,
    };

    return {
      header: headerAnimation,
      cartSummary: cartSummaryAnimations,
    };
  }, [showMobileBackground, isHeaderCollapsed, isCartSummaryCollapsed]);

  return (
    <Box id="just-form-layout" className={inIframe ? 'inIframe' : ''} ref={portalRef}>
      <LoadingWrapper isLoading={loading} isPaidLoading={paidLoading}>
        <Box
          zIndex={1000}
          id="just-form-header"
          animation={animations.header}
          top={isMobile ? '0' : undefined}
          position={isMobile ? 'sticky' : 'relative'}
        >
          <Header h={headerHeight} />

          {showMobileBackground && (
            <Box
              w="full"
              minH="6.25rem"
              zIndex="1000"
              bottom="-2.5rem"
              position="absolute"
              animation={animations.cartSummary.height}
              visibility={isCartSummaryCollapsed ? 'hidden' : 'visible'}
            >
              <Box
                left={2}
                right={2}
                bottom="0"
                position="absolute"
                display="flex"
                justifyContent="center"
                alignItems="flex-end"
                overflow="hidden"
                borderRadius="3xl"
                w="calc(100% - var(--chakra-space-4))"
                animation={animations.cartSummary.opacity}
              >
                <CartSummary />
              </Box>
            </Box>
          )}
        </Box>
        <ProgressBar hide={!showProgressBar} progress={scrollPercentage} />
        <Flex
          bg="white"
          overflowY="scroll"
          h={!showMobileBackground ? '90%' : `calc(100% - ${headerHeight})`}
        >
          <Divider
            orientation="vertical"
            hidden={!isLargerThan900}
            position="absolute"
            right="49%"
            border="0.8px solid #E0E0E0"
            borderStyle="dashed"
            h="85%"
            top="5.5rem"
          />
          <Container
            ref={mainRef}
            pb={isLargerThan900 ? 12 : isLargerThan750 ? 8 : 2}
            pl={isLargerThan900 ? 10 : isLargerThan750 ? 8 : 2}
            pr={isLargerThan900 ? 10 : isLargerThan750 ? 8 : 2}
            maxW={isLargerThan900 ? '460px' : isLargerThan750 ? '344px' : 'full'}
            m="0"
            as="main"
            flex="1 1 auto"
            overflow="auto"
            height="100%"
            display="flex"
            flexDirection="column"
            overscrollBehavior="none"
            pt={showMobileBackground ? '50px' : 2}
            w={isLargerThan900 ? '380px' : isLargerThan750 ? '300px' : 'full'}
          >
            {!showMobileBackground && isMobile && <CartSummary />}
            {children}
          </Container>
          {!isMobile &&
            (useCartStatus !== 'success' && !cart ? (
              <JustSkeleton
                width="25rem"
                height="22.5rem"
                px="12px"
                pb="12px"
                pt="6px"
                ml={isLargerThan900 ? '21px' : 0}
                mt="3.5"
                mb="26px"
                display="flex"
              />
            ) : (
              <Box
                backgroundColor="#F7F7F7"
                borderRadius="20px"
                px="12px"
                pb="12px"
                pt="6px"
                ml={isLargerThan900 ? '21px' : 0}
                mt="3.5"
                w="401px"
                mb="26px"
                display="flex"
                h="80%"
              >
                <CartDetail isUpsellDisabled={true} />
              </Box>
            ))}
        </Flex>
      </LoadingWrapper>
    </Box>
  );
};

import { useMutation } from '@tanstack/react-query';

import type { RecommendedCodesResult } from '@getjust/gateway';

import { useFormMessageAtom } from '$hooks/state';
import { useTrackAmplitude } from '$src/hooks/client/useTrackAmplitude';
import { useRecommendedDiscountsAtom } from '$src/hooks/state/useRecommendedDiscountsAtom';
import { gateway } from '$src/http';
import { RecommendedDiscountsInput } from '$src/pages/api/[shopId]/recommend-discounts';

export const MUTATION_RECOMMENDED_DISCOUNTS_KEY = 'MUTATION/MUTATION_RECOMMENDED_DISCOUNTS';

export const useRecommendedDiscounts = () => {
  const { formMessage } = useFormMessageAtom();
  const { track } = useTrackAmplitude();

  const { onRecommendedDiscount } = useRecommendedDiscountsAtom();
  return useMutation({
    mutationFn: (recommendedDiscountsInput: RecommendedDiscountsInput) =>
      gateway.post<RecommendedCodesResult>(
        `/api/${formMessage?.shopId}/recommend-discounts`,
        recommendedDiscountsInput,
      ),
    gcTime: Infinity,
    mutationKey: [MUTATION_RECOMMENDED_DISCOUNTS_KEY],
    onSuccess: ({ data }) => {
      if (data.outcome === 'found') {
        onRecommendedDiscount(data.codes);
      }
    },
    onError: (err, payload) => {
      track('Recommended discounts failed', {
        payload: JSON.stringify(payload),
      });
    },
  });
};
